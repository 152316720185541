import { LeftOutlined } from "@ant-design/icons";
import { Checkbox, Form, InputNumber, Modal, Popover, Radio, List, Tabs, Input } from "antd";
import Button from "antd/lib/button";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, currentRoleAtLeast } from "../../auth";
import { hasValue, ReturnPromiseType, sessionFormatter, usePromise } from "../../util";
import { ConsultingReport } from "../misc/session-report-consulting";
import { ExamReport } from "../misc/session-report-exam";
import { ProgressEntriesReport } from "../misc/session-report-progress";
import { useModals } from "./modals-provider";
import { ProgressGrid } from "../misc/progress-grid";
import { AssessmentReport } from "../misc/session-report-assessment";
import { ParentMeetingReport } from "../misc/session-report-parent-meeting";

export type ReportResponse = ReturnPromiseType<typeof API.Student.Enrollment.Session.GetReport>["report"];

function RegularReport(props: {
	active: boolean;
	report: ReportResponse | undefined;
	attendance: boolean | "late" | null;
	onUpdateValues: (valid: boolean, x: Record<string, unknown>) => void;
}) {
	const [presentation, setPresentation] = useState<string | null>(null);
	const [homework, setHomework] = useState<string | null>(null);
	const [project, setProject] = useState<string | null>(null);
	const [video, setVideo] = useState<string | null>(null);
	const [score, setScore] = useState<number>();
	const [remark, setRemark] = useState("");

	const propsRef = useRef(props);
	propsRef.current = props;

	useEffect(() => {
		if (propsRef.current.active) {
			propsRef.current.onUpdateValues(true, {
				presentation: presentation as any,
				homework: homework as any,
				project: project as any,
				video: video as any,
				remark,
				score,
			});
		}
	}, [presentation, homework, project, score, remark, video]);

	useEffect(() => {
		if (props.attendance === false) {
			setVideo("n/a");
			setProject("n/a");
			setHomework("n/a");
			setPresentation("n/a");
		}
	}, [props.attendance]);

	useEffect(() => {
		const reportData = props.report?.data as any;

		setPresentation(reportData?.presentation ?? null);
		setVideo(reportData?.video ?? null);
		setHomework(reportData?.homework ?? null);
		setProject(reportData?.project ?? null);
		setScore(reportData?.score ?? undefined);
		setRemark(reportData?.remark ?? "");
	}, [props.report]);

	return (
		<>
			<div>
				<span>Additional comments:</span>
				<ol>
					<li>project name/description</li>
					<li>homework assigned</li>
					<li>engagement and interaction</li>
					<li>level appropriate, too easy or too hard</li>
				</ol>
			</div>
			<Form.Item>
				<TextArea rows={3} value={remark} onChange={e => setRemark(e.target.value)} />
			</Form.Item>
			<Form.Item label="Did the student record a video">
				<Radio.Group
					onChange={e => setVideo(e.target.value as string)}
					value={video}
					disabled={props.attendance === false}
				>
					<Radio value={"true"}>Yes</Radio>
					<Radio value={"false"}>No</Radio>
					<Radio value={"n/a"}>N/A</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Did the student finish the project">
				<Radio.Group
					onChange={e => setProject(e.target.value as string)}
					value={project}
					disabled={props.attendance === false}
				>
					<Radio value={"true"}>Yes</Radio>
					<Radio value={"false"}>No</Radio>
					<Radio value={"n/a"}>N/A</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Did the student finish their homework">
				<Radio.Group
					onChange={e => setHomework(e.target.value as string)}
					value={homework}
					disabled={props.attendance === false}
				>
					<Radio value={"true"}>Yes</Radio>
					<Radio value={"false"}>No</Radio>
					<Radio value={"n/a"}>N/A</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Did the student do a presentation">
				<Radio.Group
					onChange={e => setPresentation(e.target.value as string)}
					value={presentation}
					disabled={props.attendance === false}
				>
					<Radio value={"true"}>Yes</Radio>
					<Radio value={"false"}>No</Radio>
					<Radio value={"n/a"}>N/A</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item label="Score for student out of 10">
				<InputNumber
					precision={0}
					min={0}
					max={10}
					value={score}
					onChange={e => setScore(e !== null && e > 0 ? e : undefined)}
				/>
			</Form.Item>
		</>
	);
}

const examReminderLesson = 9;

export function SessionReportEditorModal(props: {
	target:
		| {
				uuid: string;
				auth: AuthContextLoggedIn;
				enrollment: {
					uuid: string;
					student: { uuid: string; name: string };
					section?: { course: { name: string } };
				};
		  }
		| undefined;
	onSave: (id: string) => void;
	onCancel: () => void;
}) {
	const report = usePromise(
		useCallback(async () => {
			if (props.target?.uuid === undefined || props.target?.auth === undefined) return undefined;
			return (await API.Student.Enrollment.Session.GetReport(props.target.auth, { uuid: props.target.uuid }))
				.report;
		}, [props.target?.uuid, props.target?.auth]),
	);

	const prevReport = usePromise(
		useCallback(async () => {
			if (props.target?.uuid === undefined || props.target?.auth === undefined) return undefined;
			return (
				await API.Student.Enrollment.Session.GetPreviousReport(props.target.auth, { uuid: props.target.uuid })
			).report;
		}, [props.target?.uuid, props.target?.auth]),
	);

	const [activeTab, setActiveTab] = useState("default");
	const [attendance, setAttendance] = useState<boolean | null | "late">(null);
	const [lateMinutes, setLateMinutes] = useState(0);
	const [lessonNumber, setLessonNumber] = useState<number>();
	const [shortProject, setShortProject] = useState<string>("");
	const [sendExamReminder, setSendExamReminder] = useState<boolean>(false);
	const [createMessage, setCreateMessage] = useState(false);
	const [allReports, setAllReports] = useState<ReturnPromiseType<typeof API.Student.Enrollment.GetStudentReports>>();

	const [additionalData, setAdditionalData] = useState<Record<string, unknown>>({});

	const modals = useModals();

	useEffect(() => {
		const reportData = report?.data as any;
		if (report?.attendance === true && hasValue(reportData?.late) && reportData.late !== 0) {
			setAttendance("late");
		} else {
			setAttendance(report?.attendance ?? null);
		}

		if (reportData?.exam instanceof Array) {
			setActiveTab("exam");
		} else if (reportData?.entries instanceof Array) {
			setActiveTab("consulting");
		} else if (reportData?.parent_meeting instanceof Array) {
			setActiveTab("parentmeeting");
		} else if (reportData?.assessment instanceof Array) {
			setActiveTab("assessment");
		} else {
			setActiveTab("default");
		}

		setLateMinutes(reportData?.late ?? 5);
		setCreateMessage(false);
		setAllReports(undefined);
		setLessonNumber(report?.lesson_number ?? undefined);
		setShortProject((report?.data as any)?.project_name ?? "");
	}, [report]);

	useEffect(() => {
		if (lessonNumber !== examReminderLesson) {
			setSendExamReminder(false);
		} else {
			setSendExamReminder(true);
		}
	}, [lessonNumber]);

	return (
		<Modal
			maskClosable={false}
			destroyOnClose
			open={!!props.target}
			footer={
				<>
					{props.target && currentRoleAtLeast(props.target.auth, "reception") && (
						<Button
							onClick={() => {
								Modal.confirm({
									title: "Are you sure you want to delete this attendance?",
									async onOk() {
										if (!props.target) return;

										await API.Student.Enrollment.Session.DeleteReport(props.target.auth, {
											uuid: props.target.uuid,
										});
										props.onCancel();
									},
								});
							}}
						>
							Delete attendance
						</Button>
					)}
					<Button
						onClick={() => {
							Modal.confirm({
								title: "Navigating away, your unsaved work will be lost.",
								onOk() {
									props.onCancel();
								},
							});
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						disabled={attendance === null || lessonNumber == null}
						onClick={async () => {
							if (!props.target) return;

							// undefined: report not loaded yet
							// null: report doesn't exist
							if (report === undefined) return;

							if (attendance === null || lessonNumber === null) return;

							const doRest = async () => {
								if (!props.target) return;
								await API.Student.Enrollment.Session.SetReport(props.target.auth, {
									uuid: props.target.uuid,
									attendance: attendance === "late" ? true : attendance,
									lesson_number: lessonNumber,
									project_name: shortProject,
									late: attendance === "late" ? lateMinutes : undefined,
									...additionalData,
								});

								if (createMessage || activeTab === "exam") {
									let title = `Review attendance`;
									let text = `Please take note of the teacher's attendance`;
									if (activeTab === "exam") {
										title = `Exam posted`;
										text = `Please take note of the teacher's exam posting`;
									}

									await API.Ticket.Create(props.target.auth, {
										branch_uuid: props.target.auth.currentBranch.uuid,
										enrollment_uuid: props.target.enrollment.uuid,
										student_uuid: props.target.enrollment.student.uuid,
										student_session_uuid: props.target.uuid,
										title,
										text,
										recipients: [],
										recipient_roles: ["reception"],
										type: "message",
									});
								}

								if (sendExamReminder) {
									const sessions = await API.Student.Enrollment.GetSessions(props.target.auth, {
										uuid: props.target.enrollment.uuid,
									});

									const ind = sessions.student_sessions.findIndex(x => x.uuid === props.target?.uuid);
									if (ind >= 0 && ind < sessions.student_sessions.length - 1) {
										await API.Ticket.Create(props.target.auth, {
											branch_uuid: props.target.auth.currentBranch.uuid,
											enrollment_uuid: props.target.enrollment.uuid,
											student_uuid: props.target.enrollment.student.uuid,
											student_session_uuid: sessions.student_sessions[ind + 1].uuid,
											title: `Exam reminder`,
											text: `Exam will happen this class.`,
											date: sessions.student_sessions[ind + 1].section_session.datetime,
											recipients: [],
											recipient_roles: ["reception"],
											type: "message",
										});
									}
								}

								props.onSave(props.target.uuid);
							};

							if (report === null) {
								await doRest();
							} else {
								Modal.confirm({
									title: "You are about to edit this entry. Please confirm.",
									onOk: async () => {
										await doRest();
									},
								});
							}
						}}
					>
						Save
					</Button>
				</>
			}
			onCancel={() => props.onCancel()}
		>
			{props.target && (
				<>
					<div style={{ marginBottom: 20 }}>
						<Popover
							placement="left"
							onOpenChange={async e => {
								if (props.target === undefined) return;

								if (e && allReports === undefined) {
									setAllReports(
										await API.Student.Enrollment.GetStudentReports(props.target.auth, {
											uuid: props.target.enrollment.uuid,
										}),
									);
								}
							}}
							content={
								allReports && (
									<List
										size="small"
										dataSource={allReports
											.filter(x => new Date(x.section_session.datetime) < new Date())
											.slice()
											.reverse()}
										renderItem={item => {
											return (
												<Popover
													placement="left"
													content={
														<div>{(item.report?.data as any)?.remark ?? "No comments"}</div>
													}
												>
													<div key={item.uuid} className="hover-list-item" onClick={() => {}}>
														{(item.section_session.sequence_number ?? -1) + 1}.
														{` (Lesson ${item.report?.lesson_number ?? -1}) `}
														{item.uuid === props.target?.uuid ? "(Current) " : " "}
														{new Date(item.section_session.datetime).toLocaleString(
															[],
															sessionFormatter,
														)}
														{item.is_makeup ? " (Makeup) " : " "}
														{item.is_cancelled ? " (Absent) " : " "}
														{item.report?.attendance !== undefined &&
														item.report?.attendance !== null
															? item.report.attendance
																? "✔️"
																: "❌"
															: ""}
													</div>
												</Popover>
											);
										}}
									/>
								)
							}
						>
							<Button style={{ marginRight: 10 }}>
								<LeftOutlined />
								View other reports
							</Button>
						</Popover>
						<Button
							onClick={() => {
								if (!props.target) return;

								modals.display({
									args: {
										width: 1000,
										title: "Progress grid",
									},
									contents: (
										<ProgressGrid
											auth={props.target.auth}
											student={{
												uuid: props.target.enrollment.student.uuid,
											}}
										/>
									),
								});
							}}
						>
							View progress grid
						</Button>
						<div>Student name: {props.target?.enrollment?.student?.name}</div>
						<div>Course: {props.target?.enrollment?.section?.course?.name}</div>
					</div>
					<div style={{ maxHeight: "60vh", overflowY: "auto", paddingRight: 20, marginBottom: 10 }}>
						<Form.Item label="Did the student attend">
							<Radio.Group
								onChange={e => {
									setAttendance(e.target.value as any);
								}}
								value={attendance}
							>
								<Radio value={true}>Yes</Radio>
								<Radio value={"late"}>Late</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
						{attendance === "late" && (
							<Form.Item label="How many minutes late">
								<InputNumber
									min={0}
									max={60}
									onChange={e => setLateMinutes(e ?? 0)}
									value={lateMinutes}
									precision={0}
								/>
							</Form.Item>
						)}
						{prevReport && <div>Previous lesson number: {prevReport.lesson_number}</div>}
						<Form.Item label="Lesson number">
							<InputNumber
								precision={0}
								min={1}
								max={100}
								value={lessonNumber}
								onChange={e => setLessonNumber(e !== null && e > 0 ? e : 1)}
							/>
						</Form.Item>
						<Form.Item label="Short project description">
							<Input value={shortProject} onChange={e => setShortProject(e.target.value)} />
						</Form.Item>
						{lessonNumber === examReminderLesson && (
							<Checkbox checked={sendExamReminder} onChange={e => setSendExamReminder(e.target.checked)}>
								<span style={{ color: "red" }}>9th lesson: send reminder for exam next time</span>
							</Checkbox>
						)}

						<Tabs
							activeKey={activeTab}
							onChange={tab => {
								setActiveTab(tab);
							}}
							items={[
								{
									label: `Default`,
									key: "default",
									children: (
										<RegularReport
											active={activeTab === "default"}
											report={report}
											attendance={attendance}
											onUpdateValues={(valid, values) => {
												setAdditionalData(values);
											}}
										/>
									),
								},
								{
									label: `Exam`,
									key: "exam",
									children: (
										<ExamReport
											active={activeTab === "exam"}
											report={report}
											attendance={attendance}
											onUpdateValues={(valid, values) => {
												setAdditionalData(values);
											}}
										/>
									),
								},
								{
									label: `Consulting`,
									key: "consulting",
									children: (
										<ConsultingReport
											active={activeTab === "consulting"}
											report={report}
											attendance={attendance}
											onUpdateValues={(valid, values) => {
												setAdditionalData(values);
											}}
										/>
									),
								},
								{
									label: `Parent meeting`,
									key: "parentmeeting",
									children: (
										<ParentMeetingReport
											active={activeTab === "parentmeeting"}
											report={report}
											attendance={attendance}
											onUpdateValues={(valid, values) => {
												setAdditionalData(values);
											}}
										/>
									),
								},
								{
									label: `Assessment`,
									key: "assessment",
									children: (
										<AssessmentReport
											active={activeTab === "assessment"}
											report={report}
											attendance={attendance}
											onUpdateValues={(valid, values) => {
												setAdditionalData(values);
											}}
										/>
									),
								},
								{
									label: `Tutoring`,
									key: "progress",
									children: (
										<ProgressEntriesReport
											active={activeTab === "progress"}
											session={{ uuid: props.target.uuid }}
											student={{ uuid: props.target.enrollment.student.uuid }}
											auth={props.target.auth}
										/>
									),
								},
							]}
						/>
					</div>
					<Checkbox
						checked={createMessage || activeTab === "exam"}
						onChange={e => setCreateMessage(e.target.checked)}
						disabled={activeTab === "exam"}
					>
						Create a message to notify the manager of the remarks in this report?
					</Checkbox>
					{report && <>Updated at {new Date(report?.datetime).toLocaleString([], sessionFormatter)}</>}
				</>
			)}
		</Modal>
	);
}
